
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hexadash-primary: mat.define-palette(mat.$indigo-palette);
$hexadash-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hexadash-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hexadash-theme: mat.define-light-theme((
  color: (
    primary: $hexadash-primary,
    accent: $hexadash-accent,
    warn: $hexadash-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hexadash-theme);

// Theme variables, must be included before the libraries to allow overriding defaults
@tailwind base;
@tailwind components;


@tailwind utilities;

/* Apply the custom font family globally */
@layer base {
	body {
	  @apply font-sans;
	}
  }

// 3rd party libraries

// Theme customization
@import './assets/scss/app.scss';
@import './styles/fonts.css';

html, body { height: 100%; }
body { margin: 0;  font-family: 'Helvetica-Neue-LT-Arabic-75-Bold', sans-serif; }

// Custom styles for Angular Material tabs using Tailwind CSS
::ng-deep .mat-tab-group {
	@apply bg-gray-100; // Apply Tailwind background color
  }
  
  ::ng-deep .mat-tab-label {
	@apply font-bold; 
	@apply text-indigo-700; // Apply Tailwind text color
  }
  
  ::ng-deep .mat-tab-label-active {
	@apply font-bold; 
	@apply text-indigo-900 font-bold; // Apply Tailwind text color and font weight
  }
  
  ::ng-deep .mat-ink-bar {
	@apply bg-indigo-900; // Apply Tailwind background color
  }
  
.iti {
	display: block !important;
	margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 244px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #c7cace;
	border-radius: 4px;
	font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: #3498db;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}
